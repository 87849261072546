import { useEffect, useState } from "react";
import { Helpers } from "../../utils";
import { Eta } from "./Eta";
import { useOrderStatus, useOrderStatusIcons } from "./hooks";
import { Notifications } from "./Notifications";
import "./Status.scss";

export const StatusMobile = ({ detail }: APP.StatusBar) => {
    return (
        <div className="mobile-status" style={{ backgroundColor: `#${detail.order_status_color_code}` }}>
            <div className="flag" />
            <span style={{ color: Helpers.isLightShade(detail.order_status_color_code) ? "black" : "white" }}>
                {detail.order_status_name}
            </span>
        </div>
    );
};

export const StatusBar = ({ detail, toggleNotify }: APP.StatusBar) => {
    const { order_status } = useOrderStatus(detail);
    return (
        <div id="OrderStatusBar">
            {order_status?.length ? (
                <div className={`current-status ${order_status.some((e: any) => e.completed_at) ? "has-completed" : ""}`}>
                    {order_status.map((status: APP.Status, index: number) => {
                        return <StatusBlock key={index} order_status={status} />;
                    })}
                </div>
            ) : (
                <span />
            )}
            <div className="tools">
                <Notifications detail={detail} toggleNotify={toggleNotify} />
                <Eta detail={detail} />
            </div>
        </div>
    );
};

const StatusBlock = ({ order_status }: APP.StatusBlockProps) => {
    const { status, sub_status, icon_url, color, end, border } = order_status;
    return (
        <div className="order-status-item">
            <div className="status-block">
                <div className={`status ${color === "recent" ? "current-stage" : ""}`}>
                    {status}
                    <div className="sub">{sub_status ? `(${sub_status})` : ""}</div>
                </div>
                <div className={`meter ${color} ${end}-cap`}>
                    <StatusIcon icon_url={icon_url} />
                </div>
            </div>
            {border ? <div className={`${border} ${color}`} /> : <span />}
        </div>
    );
};

const StatusIcon = ({ icon_url }: APP.StatusIcon) => {
    const icons = useOrderStatusIcons();
    const [icon, setIcon] = useState<any>(null);
    useEffect(() => {
        if (icons) {
            const iconsvg = icons?.[icon_url];
            if (iconsvg) setIcon(iconsvg);
        }
    }, [icon_url, icons]);
    if (!icon) return <span />;
    return <span className="status-icon" dangerouslySetInnerHTML={{ __html: icon.outerHTML }} />;
};

