import { gql } from "@apollo/client";

const Person = gql`
    query person($personId: String!) {
        person_public(person_id: $personId) {
            id
            person {
                id
                display_name
                first_name
                last_name
                company_name
                email
                cell_phone
                phone
                address_1
                address_2
                city
                state
                zip
            }
            orders {
                archived
                order_id
                order_status_id
                order_status_name
                order_status_color_code
                notify
            }
            store {
                id
                name
                address1
                address2
                city
                state
                zip
                country_code
                logo_url
                phone
                sales_phone
                website_url
                sales_email
                testimonial
                facebook_url
                twitter_url
                linked_in_url
                why_us
                primary_photo_full_url
            }
            theme
        }
    }
`;

const OrderDetail = gql`
    query OrderDetail($orderId: String!) {
        order_detail_public(order_id: $orderId) {
            id
            documents {
                id
                name
                description
                url
                customer_can_view
            }
            order {
                id
                archived
                order_number
                stock_number
                po_number
                year
                make
                model
                trim
                status_changed_at
                eta_date
                created_date
                placed_date
                build_date
                port_railyard_date
                transit_dealer_date
                transit_factory_date
                received_date
                accepted_date
                deposit_type
                deposit_amount
                salesperson_emp {
                    office_phone
                    user {
                        first_name
                        last_name
                        email
                    }
                }
                buyer_person {
                    id
                    display_name
                    first_name
                    last_name
                    company_name
                }
                cobuyer_person {
                    id
                    display_name
                    first_name
                    last_name
                    company_name
                }
                inventory_item {
                    id
                    primary_photo_full_url
                }
            }
        }
    }
`;

export const Query = {
    Person,
    OrderDetail,
};
