import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { LayOut, Helpers, ENUMS } from "./utils";
import { useWindowResize } from "./utils/hooks";
import { Portal } from "./pages/portal/Portal";

export const useApp = (): APP.App => {
    const pages: any[] = Helpers.arrayFromEnum(ENUMS.Pages);
    const routes = pages.map(id => ({ id, name: id, path: `/:id/${id}`, view: Portal }));
    return { routes };
};

export const usePage = (page: APP.Route): APP.Page => {
    const [viewType, setViewType] = useState(ENUMS.AppViewType.mobile);
    const selectViewType = useCallback(() => setViewType(LayOut.ViewType()), []);

    useEffect(() => selectViewType(), [page, selectViewType]);
    useWindowResize(selectViewType);

    return { viewType, settitle: (title: string) => (document.title = title) };
};

export const useLoc = (page: any): APP.Location => {
    const location = useLocation();
    return {
        type: page.id,
        token: page?.computedMatch?.params?.id ?? location.pathname,
        params: Helpers.parseParams(location.search)
    };
};
