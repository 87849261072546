import { useEffect, useState } from "react";

enum EOrderStatus {
    Created = 1,
    Placed = 2,
    Accepted = 3,
    Build = 4,
    TransitFactory = 5,
    PortRailyard = 6,
    TransitDealer = 7,
    Received = 8
}

export enum StatKeys {
    Created = "created_date",
    Placed = "placed_date",
    Accepted = "accepted_date",
    Build = "build_date",
    TransitFactory = "transit_factory_date",
    PortRailyard = "port_railyard_date",
    TransitDealer = "transit_dealer_date",
    Received = "received_date",
}

const statusBlocks: APP.Status[] = [
    { id: EOrderStatus.Created, key: StatKeys.Created, status: "Created", icon_url: "created" },
    { id: EOrderStatus.Placed, key: StatKeys.Placed, status: "Placed", icon_url: "placed" },
    { id: EOrderStatus.Accepted, key: StatKeys.Accepted, status: "Accepted", icon_url: "accepted" },
    { id: EOrderStatus.Build, key: StatKeys.Build, status: "Build", icon_url: "build" },
    { id: EOrderStatus.TransitFactory, key: StatKeys.TransitFactory, status: "In Transit", sub_status: "Factory", icon_url: "factory" },
    { id: EOrderStatus.PortRailyard, key: StatKeys.PortRailyard, status: "In Port", icon_url: "port" },
    { id: EOrderStatus.TransitDealer, key: StatKeys.TransitDealer, status: "In Transit", sub_status: "Dealership", icon_url: "delivery" },
    { id: EOrderStatus.Received, key: StatKeys.Received, status: "Received", icon_url: "received" },
];

const useOrderStatusBlocks = (detail: any) => {
    const [order_status, setOrderStatus] = useState<APP.Status[]>([]);

    useEffect(() => {
        if (detail) {
            const updates = order_status?.filter((o: APP.Status) => o.completed_at !== detail[o.key]) ?? [];
            if (!order_status.length || updates.length > 0) {
                const blocks: APP.Status[] = statusBlocks?.map(stat => {
                    return { ...stat, ...{ completed_at: detail[stat.key] } };
                }) ?? [];
                const completed: APP.Status[] = blocks?.filter(s => s.completed_at) ?? [];
                const getDisplay = (status: APP.Status, index: number) => ({
                    last: index === blocks.length - 1,
                    complete: completed?.indexOf(status) > -1 ?? false,
                    recent: completed?.indexOf(status) === completed?.length - 1 ?? false,
                });
                const status: APP.Status[] = blocks.map((status, index) => {
                    const { last, complete, recent } = getDisplay(status, index);
                    return {
                        ...status,
                        end: index === 0 ? "left" : last ? "right" : "thru",
                        color: recent && complete ? "recent" : complete ? "complete" : "todo",
                        border: ((recent && complete) || complete) && !last ? "progress" : !last ? "divide" : undefined,
                    };
                });
                setOrderStatus(status);
            }
        }
    }, [detail, order_status]);

    return { ...detail, order_status };
};

export const useOrderStatusIcons = () => {
    const [icons, setIcons] = useState(null);

    useEffect(() => {
        (async function getIcons() {
            if (!icons) {
                const iconSVG: any = {};
                const icons = await fetch("assets/icon/order-status-icons.svg");
                const text = await icons.text();
                const parser = new DOMParser();
                const parsedText = parser.parseFromString(text, "image/svg+xml");
                const symbols = parsedText.querySelectorAll("symbol");
                symbols.forEach((symbol: any) => {
                    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                    while (symbol.firstChild) svg.appendChild(symbol.firstChild);
                    [...symbol.attributes].forEach(attr => svg.attributes.setNamedItem(attr.cloneNode()));
                    svg.classList.add(`icon-${svg.id}`);
                    iconSVG[svg.id] = svg;
                });
                setIcons(iconSVG);
            }
        })();
    });

    return icons;
};

export const useOrderStatus = (order: APP.OrderDetail) => {
    const order_status = useOrderStatusBlocks(order);
    return order_status;
};
