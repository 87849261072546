import { IonIcon } from "@ionic/react";
import { refreshOutline } from "ionicons/icons";
import { Tabs } from "../../components";
import { ENUMS, Helpers } from "../../utils";
import { useOrders } from "../../utils/hooks";
import { Order } from "./Order";

import "./Orders.scss";

export const Orders = ({ viewType, person, perfersDark, refetch, toggleNotify }: APP.Screen) => {
    const orders = useOrders(person.orders);
    const tabs: any[] = Helpers.arrayFromEnum(ENUMS.OrderTabs).filter((tab, index) => orders?.[index]?.length > 0);
    const activeOrders = (active: string): APP.Order[] => (orders[tabs.indexOf(active)]);

    return (
        <div id="Orders" className={viewType}>
            <IonIcon className="refresh" icon={refreshOutline} onClick={() => refetch()} />
            <Tabs
                viewType={viewType}
                tabs={tabs}
                renderTab={active => {
                    return activeOrders(active).map((order, index) => {
                        return <Order key={`${order.order_id}${index}`} viewType={viewType} perfersDark={perfersDark} order={order} toggleNotify={toggleNotify} />;
                    });
                }} />
        </div>
    );
}