import { Helpers } from "../../utils";

export const Eta = ({ detail }: APP.EtaProps) => {
    const { start, stop } = Helpers.getWeekRange(detail.eta_date);
    if (!detail.eta_date) return <div className="eta">{detail.archived ? "Closed" : "ETA not available at this time"}</div>;
    return (
        <div className="eta">
            ETA: <strong>{start}</strong> to <strong>{stop}</strong>
        </div>
    )
};
