import { IonToggle } from "@ionic/react"

export const Notifications = ({ detail, toggleNotify }: APP.NotifyProps) => {
    return (
        <div className="notifications">
            <IonToggle color="primary" checked={detail.notify} onClick={() => toggleNotify(!detail.notify, detail.order_id)} />
            <span>Notifications</span>
        </div>
    )
};
