import { useQuery } from "@apollo/client";
import { useIonLoading } from "@ionic/react";
import { useEffect, useLayoutEffect, useState } from "react";
import { ENUMS } from "./enums";
import { Query } from "./gql";
import { Helpers } from "./helpers";

const WebFont = require('webfontloader');

export const useWindowResize = (callback: () => void) => {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useLayoutEffect(() => {
        const update = () => setSize([window.innerWidth, window.innerHeight]);
        window.addEventListener("resize", update);
        return () => window.removeEventListener("resize", update);
    }, []);
    return useEffect(() => callback(), [size]); // eslint-disable-line
};

export const useDarkMode = (theme?: APP.Theme): boolean => {
    const watch = window.matchMedia('(prefers-color-scheme: dark)');

    const usingTheme = theme?.dark_mode !== null ?? false;
    const themeSetting = theme?.dark_mode ?? false;

    const [prefersDark, setPrefersDark] = useState(usingTheme ? themeSetting : watch.matches);

    useEffect(() => {
        const callback = (dark: any) => setPrefersDark(dark.matches);
        if (!usingTheme) watch.addEventListener("change", callback);
        return () => watch.removeEventListener("change", callback);
    }, [watch, usingTheme]);

    useEffect(() => {
        document.documentElement.setAttribute("contact-theme", prefersDark ? "dark" : "light");
        document.getElementById("Contact")?.setAttribute("contact-theme", prefersDark ? "dark" : "light");
    }, [prefersDark])

    return prefersDark;
}

interface ThemeFont { type: string, config: { families: string[], urls?: string[] }, fonts: string[] };
const useFont = (theme: APP.Theme | undefined): ThemeFont | undefined => {
    if (!theme) return;

    const type = theme.font_type;
    let config: any = { families: [theme.font, theme.bold_font] }
    let fonts: any = [theme.font, theme.font_family, theme.bold_font, theme.bold_font_family];

    if (type === "custom") {
        if (theme.font === "AudiType-WideNormal") {
            const audi = Helpers.getAudiFont();
            config = { families: audi.families, urls: audi.urls };
            fonts = audi.fonts;
        } else {
            const mercedes = Helpers.getMercFont();
            config = { families: mercedes.families, urls: mercedes.urls };
            fonts = mercedes.fonts;
        }
    }

    return { type, config, fonts };
}

export const useThemeSetting = (data: any) => {
    if (!data || !data?.person_public) return Helpers.defaultTheme;
    const parsed = data?.person_public?.theme && JSON.parse(data.person_public.theme);
    const theme = parsed?.settings?.[0]?.value && JSON.parse(parsed.settings[0].value);
    return theme;
};

export const useTheme = (theme?: APP.Theme) => {
    const prefersDark = useDarkMode(theme);
    const font = useFont(theme);

    useEffect(() => {
        if (!theme) return;

        // desktop gutters width
        if (theme.content_width) document.documentElement.style.setProperty("--theme-content-width", theme.content_width);

        // fonts
        if (font) {
            const { type, config, fonts } = font;
            WebFont.load({ [type]: config });
            // set font
            document.documentElement.style.setProperty("--theme-font", `${fonts[0]}, ${fonts[1]}`);
            document.documentElement.style.setProperty("--theme-bold-font", `${fonts[2]}, ${fonts[3]}`);
        }

        // colors
        const setColor = (key: string, val: any) => {
            document.documentElement.style.setProperty(key, val);
            document.documentElement.style.setProperty(`${key}-rgb`, `${Helpers.hexToRgb(val)}`);
            document.documentElement.style.setProperty(`${key}-tint`, `${Helpers.shadeColor(val, 5)}`);
            document.documentElement.style.setProperty(`${key}-shade`, `${Helpers.shadeColor(val, 10)}`);
        }

        // ----- check for colors or use defaults
        if (theme.primary_color) setColor("--ion-color-primary", theme.primary_color);
        if (theme.secondary_color) setColor("--ion-color-secondary", theme.secondary_color);
        if (theme.tertiary_color) setColor("--ion-color-tertiary", theme.tertiary_color);

    }, [theme, prefersDark, font])
}

export const usePerson = (location: APP.Location) => {
    return useQuery(Query.Person, { variables: { personId: location.token }, fetchPolicy: "network-only" });
};

export const useAddress = (addr: APP.Address) => {
    return addr.address_1 || addr.address1 ? `${addr.address_1 || addr.address1}${addr.address_2 || addr.address2 ? ` ${addr.address_2 || addr.address2}` : ""} ${addr.city}, ${addr.state} ${addr.zip}` : "";
};

export const useOrders = (orders: APP.Order[]) => {
    const [open, closed] = Helpers.partition(orders, (order) => !order.archived);
    return [open, closed];
};

export const useOrder = (orderId: string) => {
    return useQuery(Query.OrderDetail, { variables: { orderId }, fetchPolicy: "network-only" });
};

export const useLoading = (isLoading: boolean) => {
    const [present, dismiss] = useIonLoading();
    useEffect(() => {
        if (isLoading) {
            present({
                message: "Loading...",
                duration: 1000,
                spinner: "circles"
            })
        } else dismiss();
    }, [isLoading, present, dismiss]);
};

export const useArrowCtrls = (ctrls: { top?: () => void, btm?: () => void, lft?: () => void, rgt?: () => void }, enabled = true) => {
    return useEffect(() => {
        if (enabled) document.onkeydown = (e: any) => {
            e = e || window.event;
            const key = ENUMS.ArrowCtrlKeys[e.keyCode];
            if (key === "top") ctrls.top?.();
            if (key === "bottom") ctrls.btm?.();
            if (key === "left") ctrls.lft?.();
            if (key === "right") ctrls.rgt?.();
        }
        else document.onkeydown = () => { };
    }, [ctrls, enabled]);
}
