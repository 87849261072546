
import { Fallback } from "../fallback/fallback";
import { Orders } from "../orders/Orders";
import { ENUMS, Helpers, LayOut } from "../../utils";
import { IonIcon, IonImg } from "@ionic/react";
import { useAddress, useDarkMode, useThemeSetting } from "../../utils/hooks";
import { callOutline, earthOutline, mapOutline } from "ionicons/icons";

export const Header = ({ store, person, viewType }: APP.HeaderProps) => {
    const storeAddr = useAddress(store);
    const personAddr = useAddress(person);

    return (
        <div className={LayOut.Block(viewType, "page-header")}>
            <div className={LayOut.Block(viewType, "store")}>
                {store.logo_url ? <IonImg className="storelogo" src={store.logo_url} /> : ""}
                {viewType === ENUMS.AppViewType.mobile ? (
                    <div className="contact">
                        {store.phone ? <IonIcon icon={callOutline} className="dealer-contact" onClick={() => window.open(`tel:${store.phone}`, "_self")} /> : ""}
                        {store.address1 || store.address_1 ? <IonIcon icon={mapOutline} className="dealer-contact" onClick={() => window.open(Helpers.getGoogleAddress(store), "_blank")} /> : ""}
                        {store.website_url ? <IonIcon icon={earthOutline} className="dealer-contact" onClick={() => window.open(store.website_url, "_blank")} /> : ""}
                    </div>
                ) : (
                    <div className="info">
                        <h3 className="header">{store.name}</h3>
                        <p className="text">
                            <InfoLink newTab={true} lnk={Helpers.getGoogleAddress(store)} lbl={storeAddr} />
                        </p>
                        <p className="text">
                            {store.phone ? <InfoLink lnk={`tel:${store.phone}`} lbl={Helpers.formatPhone(parseInt(store.phone))} /> : ""}
                            {store.website_url ? " | " : ""}{store.website_url ? <InfoLink newTab={true} lnk={store.website_url} lbl={store.website_url} /> : ""}
                        </p>
                    </div>
                )}
            </div >
            <div className="person">
                <h3 className="header">{person?.display_name ?? ""}</h3>
                {viewType !== ENUMS.AppViewType.mobile ? (
                    <>
                        <p className="text">{personAddr}</p>
                        <p className="text">
                            {person.email ? <InfoLink lnk={`mailto:${person.email}`} lbl={person.email} /> : ""}{person.phone ? <span>{`${person.email ? " | " : ""}`}<InfoLink lnk={`tel:${person.phone}`} lbl={Helpers.formatPhone(parseInt(person.phone))} /></span> : ""}
                        </p>
                    </>
                ) : (
                    <span />
                )}
            </div>
        </div >
    );
};

export const Layout = ({ type, viewType, person, refetch, toggleNotify }: APP.Layout) => {
    const perfersDark = useDarkMode(useThemeSetting(person.theme));
    // Add new screens here
    switch (type) {
        case ENUMS.Pages.orders:
            return <Orders viewType={viewType} person={person} toggleNotify={toggleNotify} refetch={refetch} perfersDark={perfersDark} />;
        default:
            return <Fallback />;
    }
};

export const Footer = ({ viewType }: { viewType: ENUMS.AppViewType }) => {
    return (
        <div className={LayOut.Block(viewType, "footer")}>
            <a href="https://www.loopmein.app/" rel="noreferrer" target="_blank" className="quiet-link no-hover">
                <span className="provided">Provided by</span>
                <IonImg className="lmilogo" src="https://loopmein.imgix.net/logos/3-prong-logo.png?q=30&w=65" />
            </a>
        </div>
    );
};

export const InfoLink = ({ lnk, lbl, newTab }: { lnk?: string, lbl?: string | null, newTab?: boolean }) => {
    if (!lnk || !lbl) return <span />;
    return <a href={lnk} target={newTab ? "_blank" : "_self"} rel="noreferrer" className="quiet-link">{lbl}</a>;
};
