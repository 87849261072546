import { IonIcon, IonImg, IonModal } from "@ionic/react";
import { carSportOutline, closeCircleOutline, documentsOutline } from "ionicons/icons";
import { useState } from "react";
import { Popup } from "../../components";
import { Carousel } from "../../components/Carousel";
import { ENUMS } from "../../utils";

interface VehicleDetail {
    order: APP.OrderDetail;
    viewType: ENUMS.AppViewType;
}

export const Vehicle = ({ order, viewType }: VehicleDetail) => {
    const { documents, year, make, model, trim, stock_number, po_number, order_number, inventory_item, buyer_person } = order;
    return (
        <div className="vehicle">
            <div className="photo">
                {inventory_item?.primary_photo_full_url ? <IonImg src={inventory_item.primary_photo_full_url} /> : <IonIcon icon={carSportOutline} />}
            </div>
            {documents.length ? (
                <div className="actions">
                    <DocumentsIcon order={order} viewType={viewType} />
                </div>
            ) : <></>}
            <div className="details">
                <h4 className="header primary">{year} {make} {model} {trim}</h4>
                <p className="text">
                    {stock_number && <span className="deet"><label>STK#</label> {stock_number}</span>}
                    {po_number && <span className="deet"><label>PO#</label> {po_number}</span>}
                    {order_number && <span className="deet"><label>ORDER#</label> {order_number}</span>}
                </p>
                <p className="text">
                    {buyer_person && <span className="deet"><label>BUYER</label> {buyer_person.display_name}</span>}
                </p>
            </div>
        </div>
    )
};

export const VehicleMobile = ({ order, viewType }: VehicleDetail) => {
    const { year, make, model, trim, stock_number, po_number, documents } = order;

    return (
        <div className="vehicle mobile">
            <div className="details">
                {documents.length ? (
                    <div className="actions">
                        <DocumentsIcon order={order} viewType={viewType} />
                    </div>
                ) : <></>}
                <h4 className="header">{year} {make} {model} {trim}</h4>
                {stock_number && <p className="text">
                    <span className="deet"><label>STK#</label> {stock_number}</span>
                </p>}
                {po_number && <p className="text">
                    <span className="deet"><label>PO#</label> {po_number}</span>
                </p>}
            </div>
        </div>
    )
};

const DocumentsIcon = ({ order, viewType }: VehicleDetail) => {
    const orderDocsId = `${order.order_id}-docs`;
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Popup elemId={orderDocsId} trigger={ENUMS.PopTrig.hover} position={ENUMS.PopPos.bottom} text="Documents">
            <div id={orderDocsId}>
                <IonModal id={`${orderDocsId}-modal`} isOpen={isOpen} onDidDismiss={() => setIsOpen(false)} className="desktop xlarge">
                    <div className="modal-content">
                        <div className="content">
                            <span className="close-icon" onClick={() => setIsOpen(false)}>
                                <IonIcon icon={closeCircleOutline} size="large" />
                            </span>
                            <Carousel
                                type={viewType === ENUMS.AppViewType.mobile ? ENUMS.Carousel.swipe : ENUMS.Carousel.max}
                                items={order.documents}
                                itemkey="url"
                                altkeys={["url"]}
                                onClose={() => setIsOpen(false)}
                                preference={order.perfersDark ? "dark" : "light"}
                            />
                        </div>
                    </div>
                </IonModal>
                <IonIcon icon={documentsOutline} className="docs" onClick={() => setIsOpen(true)} />
            </div>
        </Popup>
    );
};
