import { useLoc, usePage } from "../../Config";
import { useIonToast } from "@ionic/react";
import { App404 } from "../../components";
import { useLoading, usePerson, useTheme, useThemeSetting } from "../../utils/hooks";
import { Layout, Header, Footer } from "./Layout";

import "./Portal.scss";

export const Portal = (page: APP.Route) => {
    const location = useLoc(page);
    const { viewType } = usePage(page);
    const { loading, data, error, refetch } = usePerson(location);
    
    useTheme(useThemeSetting(data));
    useLoading(loading);

    const [present] = useIonToast();

    if (error) return <App404 message={error.message} />;
    if (loading || !data.person_public) return <span />;

    const { person, store } = data.person_public;

    const toggleNotify = async (notify: boolean, order_id: string) => {
        try {
            const data: any = { notify };
            const response = await fetch(`${process.env.REACT_APP_REST_URL}/contact/person/${person.id}/subject/${order_id}`, {
                headers: { 'Content-Type': 'application/json' },
                method: "PUT",
                body: JSON.stringify(data),
            });
            const json = await response.json();
            refetch();
            present({
                message: json.message,
                duration: 1500,
                color: "success",
                position: "bottom"
            });
        } catch (error) {
            console.error(error);
            present({
                message: "There was a problem",
                duration: 1500,
                color: "danger",
                position: "bottom",
            });
        }
    };

    return (
        <div id="Portal">
            <div className={`contained ${viewType}`}>
                <Header store={store} person={person} viewType={viewType} />
                <Layout type={location.type} viewType={viewType} person={data.person_public} refetch={refetch} toggleNotify={toggleNotify} />
                <Footer viewType={viewType} />
            </div>
        </div>
    );
};
