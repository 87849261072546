import { App404 } from "../../components";
import { ENUMS, Helpers, LayOut } from "../../utils";
import { useOrder } from "../../utils/hooks";
import { InfoLink } from "../portal/Layout";
import { Eta } from "./Eta";
import { Notifications } from "./Notifications";
import { StatusBar, StatusMobile } from "./Status";
import { Vehicle, VehicleMobile } from "./Vehicle";

export const Order = ({ order, perfersDark, viewType, toggleNotify }: APP.OrderProps) => {
    const { error, data } = useOrder(order.order_id);
    if (error) return <App404 message={error.message} />;

    const detail = data?.order_detail_public?.order;
    const documents = data?.order_detail_public?.documents ?? [];
    const orderDetail: APP.OrderDetail = { ...order, ...detail, documents, perfersDark };

    if (viewType === ENUMS.AppViewType.mobile) return <MobileOrderDetail viewType={viewType} order={orderDetail} toggleNotify={toggleNotify} />;
    return <OrderDetail viewType={viewType} order={orderDetail} toggleNotify={toggleNotify} />;
};

const OrderDetail = ({ viewType, order, toggleNotify }: APP.OrderDetailProps) => {
    return (
        <div className="order">
            <div className={LayOut.Block(viewType, "info")}>
                <Vehicle order={order} viewType={viewType} />
                <div className="right">
                    <SalesPerson {...order} />
                    {order.deposit_type && order.deposit_amount && <p className="text"><strong>{Helpers.formatCurrency(order.deposit_amount)}</strong> deposit via <strong>{order.deposit_type}</strong></p>}
                </div>
            </div>
            <StatusBar detail={order} toggleNotify={toggleNotify} />
        </div>
    )
};

const MobileOrderDetail = ({ viewType, order, toggleNotify }: APP.OrderDetailProps) => {
    const { order_number, buyer_person } = order;
    return (
        <div className="order">
            <div className="info">
                <div className="lid">
                    {order_number && <span className="deet primary"><label>ORDER#</label><br />{order_number}</span>}
                    <StatusMobile detail={order} toggleNotify={toggleNotify} />
                </div>
                <div className="points">
                    <label>Vehicle</label>
                    <div className="point">
                        <VehicleMobile order={order} viewType={viewType} />
                    </div>
                </div>
                {buyer_person && (
                    <div className="points">
                        <label>Buyer</label>
                        <div className="point">
                            {buyer_person?.display_name}
                        </div>
                    </div>
                )}
                {order.deposit_type && order.deposit_amount ? (
                    <div className="points">
                        <label>Deposit</label>
                        <div className="point">
                            <strong>{Helpers.formatCurrency(order.deposit_amount)}</strong> deposit via <strong>{order.deposit_type}</strong>
                        </div>
                    </div>
                ) : ""}
                {order.salesperson_emp && (
                    <div className="points">
                        <label>Salesperson</label>
                        <div className="point">
                            <SalesPerson {...order} />
                        </div>
                    </div>
                )}
                <Eta detail={order} />
                <Notifications detail={order} toggleNotify={toggleNotify} />
            </div>
        </div>
    )
};

const SalesPerson = (order: APP.OrderDetail) => {
    if (!order.salesperson_emp) return <span />;
    const { user, office_phone }: APP.SalesPerson = order.salesperson_emp;
    const { first_name, last_name, email } = user;
    return (
        <div className="salesperson">
            <h5 className="header"><label>Salesperson:</label> {`${first_name} ${last_name}`}</h5>
            <p className="text">
                {email && <InfoLink lbl={email} lnk={`mailto:${email}`} />}
                {office_phone ? " | " : ""}{office_phone ? <InfoLink lnk={`tel:${office_phone}`} lbl={Helpers.formatPhone(parseInt(office_phone))} /> : ""}
            </p>
        </div>
    )
};
