import { ENUMS } from "./";

const ViewType = () => {
    const res = window.innerWidth;
    let type = ENUMS.AppViewType.mobile;
    if (res < 768) type = ENUMS.AppViewType.mobile;
    else if (res >= 768 && res < 1333) type = ENUMS.AppViewType.tablet;
    else if (res >= 1333) type = ENUMS.AppViewType.desktop;
    return type;
};

const Block = (viewType: ENUMS.AppViewType, decorators?: string) => `linear-flex-block ${ENUMS.AppViewType[viewType]} ${decorators ? decorators : "simple"}`

export const LayOut = {
    ViewType,
    Block,
}