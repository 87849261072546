import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonIcon, IonPage } from "@ionic/react";
import { warningOutline } from "ionicons/icons";
import { useEffect } from "react";

export const App404 = ({ message = "Oops, the page you were looking for does not exist." }) => {
    useEffect(() => {
        document.title = "404 Not Found";
    }, []);

    return (
        <IonPage>
            <IonContent>
                <div id="fourofour">
                    <IonCard>
                        <IonCardHeader>
                            <IonIcon icon={warningOutline} color="white" />
                            <IonCardTitle>{message}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            If this is a mistake please email us at <a href="mailto:support@loopmein.app">support@loopmein.app</a>.
                        </IonCardContent>
                    </IonCard>
                </div>
            </IonContent>
        </IonPage>
    );
};
