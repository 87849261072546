const arrayFromEnum = (object: any) => (Object.values(object) as Array<keyof typeof object>).map(id => id);
const parseParams = (search: any) => search !== "" && JSON.parse('{"' + decodeURI(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
const formatPhone = (num?: number | null) => {
    if (!num) return;
    const cleaned = ('' + num).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) return `${match[1]}-${match[2]}-${match[3]}`;
    return null;
};
const partition = (array: any[], isValid: (e: any) => boolean) => {
    return array.reduce(([open, closed], elem) => {
        return isValid(elem) ? [[...open, elem], closed] : [open, [...closed, elem]];
    }, [[], []]);
};
const getAudiFont = () => {
    return {
        families: ["AudiType-WideNormal", "AudiType-Bold"],
        urls: ["/assets/fonts/audi/normal/font.css", "/assets/fonts/audi/bold/font.css"],
        fonts: ["AudiType-WideNormal", "sans-serif", "AudiType-Bold", "sans-serif"]
    };
};
const getMercFont = () => {
    return {
        families: ["Daimler CS Light Regular"],
        urls: ["/assets/fonts/mercedes/font.css"],
        fonts: ["Daimler CS Light Regular", "sans-serif", "Daimler CS Light Regular", "sans-serif"]
    };
};
const hexToRgb = (hex: string) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
};
const shadeColor = (color: string, percent: number) => {
    if (color) {
        let R = parseInt(color.substring(1, 3), 16);
        let G = parseInt(color.substring(3, 5), 16);
        let B = parseInt(color.substring(5, 7), 16);
        const createTone = (ct: any) => {
            const tone = (ct * (100 + percent)) / 100;
            return tone.toString();
        };
        R = parseInt(createTone(R));
        G = parseInt(createTone(G));
        B = parseInt(createTone(B));
        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;
        const RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
        const GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
        const BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);
        return "#" + RR + GG + BB;
    } else return "#777";
};
const isLightShade = (color: any) => {
    if (!color) return false;
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
};
const getWeekRange = (datestring?: string) => {
    if (!datestring) return {};
    const format = (dateObj: Date) => {
        const day = dateObj.toLocaleString('default', { day: "2-digit" });
        const month = dateObj.toLocaleString('default', { month: "short" });
        const year = dateObj.toLocaleString('default', { year: "numeric" });
        return `${day}-${month}-${year}`;
    };
    const [yyyy, mm, dd] = datestring.split("-");
    return { start: format(new Date(+yyyy, +mm - 1, +dd)), stop: format(new Date(+yyyy, +mm - 1, +dd + 7)) };
};
const getGoogleAddress = (addr: APP.Address) => {
    const addrProps = Object.keys(addr) as Array<keyof typeof addr>;
    const addrQuery = addrProps.filter((i: keyof APP.Address) => addr[i]).map((prop: keyof APP.Address) => (prop !== "zip") ? `${addr[prop]},` : addr[prop]).join(" ");
    return `http://maps.google.com/?q=${addrQuery}`;
};
const defaultTheme: APP.Theme = {
    font_type: "google",
    font: "Open Sans",
    font_family: "sans-serif",
    bold_font: "Open Sans",
    bold_font_family: "sans-serif",
    primary_color: "#ae72af",
    secondary_color: "#1dafec",
    tertiary_color: "#5260ff",
    dark_mode: null,
    display_docs: 3,
    content_width: "1440px",
};
const formatCurrency = (value: any, digits = 2) => {
    try {
        if (value === null || typeof value !== "number") return null;
        const formatted = value.toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return "$" + formatted;
    } catch (error) {
        console.error(`There was an error formatting currency: ${error}`);
    }
};
const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8); // eslint-disable-line
        return v.toString(16);
    });
};
const setIndex = {
    prev: (a: number, b: number) => (a === 0 ? b - 1 : a - 1),
    next: (a: number, b: number) => (a === b ? 0 : a + 1)
};

export const Helpers = {
    defaultTheme,
    setIndex,
    arrayFromEnum,
    formatPhone,
    getAudiFont,
    getMercFont,
    hexToRgb,
    parseParams,
    partition,
    shadeColor,
    isLightShade,
    getWeekRange,
    getGoogleAddress,
    formatCurrency,
    uuid,
}
