export namespace ENUMS {
    export enum AppViewType {
        mobile = "mobile",
        tablet = "tablet",
        desktop = "desktop"
    }
    export enum Pages {
        orders = "orders"
    }
    export enum OrderTabs {
        open = "Open Orders",
        closed = "Closed Orders"
    }
    export enum PopPos {
        top = "top",
        bottom = "btm",
        left = "lft",
        right = "rgt"
    }
    export enum PopTrig {
        hover = "hover",
        click = "click"
    }
    export enum Carousel {
        standard = 1,
        swipe = 2,
        max = 3
    }
    export enum ArrowCtrlKeys {
        top = 38,
        bottom = 40,
        left = 37,
        right = 39
    }
}