import { useState } from "react";
import { ENUMS } from "../utils";

import "./components.scss";

export const Tabs = ({ viewType, tabs, renderTab }: APP.TabsProps) => {
    const [active, setActive] = useState(tabs[0]);
    const isMobile = viewType === ENUMS.AppViewType.mobile;
    return (
        <>
            <div className="tabs">
                {isMobile ? (
                    <div className="tab active" >
                        {active}
                    </div>
                ) : (
                    tabs.map(tab => {
                        return (
                            <div
                                key={tab}
                                className={`tab ${active === tab ? "active" : ""}`}
                                onClick={() => setActive(tab)}
                            >
                                {tab}
                            </div>
                        );
                    })
                )}
            </div>
            <div className="tab-body">
                {renderTab(active)}
            </div>
            {isMobile ? tabs.filter(i => i !== active).map(tab => {
                return (
                    <div key={tab} className="mobile-tab" onClick={() => setActive(tab)}>
                        Show {tab}
                    </div>
                );
            }) : <span />}
        </>
    )
};
